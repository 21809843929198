<template>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item label="Наименование категории">
      <a-input v-decorator="[
          'Name',
          { initialValue: formData.Name,
          rules: [{ required: true, message: 'Пожалуйста заполните Наименование категории' }] },
        ]" />
    </a-form-item>

    <a-form-item label="Родительская категория">
      <a-select
        @change="selectParent"
        placeholder="Родительская категория"
        v-decorator="[
          'ParentId',
          { initialValue: formData.Parent.Id } ]">
        <a-select-option
          v-for="category in categories"
          :key="category.Name"
          :value="category.Id">
          {{ category.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'vendors' }">Отмена</router-link>
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { formatResponseValidatorFields } from '../../helpers';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'category_edit' });
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      formData: [],
      categories: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          this.$api.saveCategory(this.$router.currentRoute.params, values, () => {
            this.$router.push({ name: 'categories' });
            this.loading = false;
          }, ({ data, status }) => {
            const fields = formatResponseValidatorFields(data, values);
            this.loading = false;
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }
        this.loading = true;
      });
    },
    selectParent(value) {
      this.form.setFieldsValue({
        ParentId: value,
      });
    },
    fetch() {
      this.$api.getCategory(this.$router.currentRoute.params, false, ({ data }) => {
        this.formData = data.data;
      });

      this.$api.getCategoriesList(false, ({ data }) => {
        this.categories = data.data;
      });
    },
  },
};
</script>
